import React, { useEffect } from "react";
import { graphql } from "gatsby";

// components
import { SortedCards, Seo, CollectionsLinks } from "../../components";
import Layout from "../../components/Layout";

const CityDiaries = ({ data }) => {
  const books = data.diaries.nodes;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  return (
    <Layout>
      <Seo title="City Diaries" />
      <CollectionsLinks />
      <SortedCards books={books} title="City Diaries" />
    </Layout>
  );
};

export const query = graphql`
  query GetCityDiaries {
    diaries: allAirtable(
      filter: {
        data: { categories: { eq: "City Diaries" }, status: { ne: "Sold-Out" } }
      }
      sort: { fields: data___Release_Date, order: DESC }
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
  }
`;

export default CityDiaries;
